import styles from "./Input.module.css";

interface Props {
  value: string;
  placeholder?: string;
  error?: string;
  type?: string;
  onChange: (text: string) => void;
}

const Input = ({
  value,
  placeholder,
  error,
  type = "email",
  onChange,
}: Props) => {
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Input;
