import { API } from "./API";

const signupInterest = async (email: string) => {
  return await API.post(
    `/api/v1/interests/signup-interest`,
    {
      data: { email },
    },
    false
  );
};

export { signupInterest };
