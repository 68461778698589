import { Svgs, constants } from "res";
import styles from "./SocialButtons.module.css";
import { firebaseLogEvent } from "utils/fbAnalytics";

const SocialButtons = () => {
  const onInstagramPress = () => {
    firebaseLogEvent("social_instagram_link");
    window.open(constants.socialLinks.INSTAGRAM_LINK, "_blank");
  };

  const onFaceBookPress = () => {
    firebaseLogEvent("social_facebook_link");
    window.open(constants.socialLinks.FACEBOOK_LINK, "_blank");
  };
  const onTikTokPress = () => {
    firebaseLogEvent("social_tiktok_link");
    window.open(constants.socialLinks.TIKTOK_LINK, "_blank");
  };

  const onTwitterPress = () => {
    firebaseLogEvent("social_twitter_link");
    window.open(constants.socialLinks.TWITTER_LINK, "_blank");
  };

  const onYoutubePress = () => {
    firebaseLogEvent("social_youtube_link");
    window.open(constants.socialLinks.YOUTUBE_LINK, "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.socialIconContainer} onClick={onInstagramPress}>
        <img width={28} src={Svgs.InstagramIcon} />
      </div>

      <div
        className={`${styles.socialIconContainer} ${styles.socialIconContainerMarginLeft}`}
        onClick={onFaceBookPress}
      >
        <img width={13} src={Svgs.FacebookIcon} />
      </div>

      <div
        className={`${styles.socialIconContainer} ${styles.socialIconContainerMarginLeft}`}
        onClick={onTikTokPress}
      >
        <img width={23} src={Svgs.TikTokIcon} />
      </div>

      <div
        className={`${styles.socialIconContainer} ${styles.socialIconContainerMarginLeft}`}
        onClick={onTwitterPress}
      >
        <img width={23} src={Svgs.TwitterIcon} />
      </div>

      <div
        className={`${styles.socialIconContainer} ${styles.socialIconContainerMarginLeft}`}
        onClick={onYoutubePress}
      >
        <img width={30} src={Svgs.YoutubeIcon} />
      </div>
    </div>
  );
};

export default SocialButtons;
