import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import signupInterestReducer, {
  SignupInterestState,
} from "./signupInterest/signupInterestReducer";
import authReducer, { AuthState } from "./auth/authReducer";
import emailUnsubscribeReducer, {
  EmailUnsubscribeState,
} from "./emailUnsubscribe/emailUnsubscribeReducer";

export interface IRootState {
  signupInterest: SignupInterestState;
  auth: AuthState;
  emailUnsubscribe: EmailUnsubscribeState;
}

const reducer = combineReducers({
  signupInterest: signupInterestReducer,
  auth: authReducer,
  emailUnsubscribe: emailUnsubscribeReducer,
});

const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
