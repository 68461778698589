import { Pngs } from "res";
import styles from "./SignupInterestTermsPrivacyScreen.module.css";

const SignupInterestTermsPrivacyScreen = () => {
  return (
    <div className={styles.container}>
      <img src={Pngs.YoupetLogo} width={200} />
      <div className={styles.title}>Privacy Policy and Terms of Service</div>
      <div className={styles.body}>
        By submitting your email address, you agree to receive a one-time email
        from us when our app is released. We will not use your email address for
        any other purpose or share it with any third parties. Your email address
        will be deleted from our system after the release announcement is sent.
        We reserve the right to modify or terminate our email subscription
        service at any time, for any reason, without notice.
      </div>
    </div>
  );
};

export default SignupInterestTermsPrivacyScreen;
