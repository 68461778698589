import MainRouter from "routes";
import "./App.css";
import "./res/colors/colors.css";
import { LoadingSpinner } from "components";
import { useFontsLoaded } from "hooks/assetsLoad/useFontsLoaded";

const App = () => {
  const fontsLoaded = useFontsLoaded();

  return (
    <div className="App">
      {!fontsLoaded ? (
        <div className="Loading-Indicator">
          <LoadingSpinner size={50} />
        </div>
      ) : (
        <MainRouter />
      )}
    </div>
  );
};

export default App;
