import { createSlice } from "@reduxjs/toolkit";
import { passwordResetChangeAction } from "./authActions";

export interface AuthState {
  passwordResetChange: {
    isChanging: boolean;
    message?: string;
    success: boolean;
  };
}

const initialState: AuthState = {
  passwordResetChange: {
    isChanging: false,
    message: undefined,
    success: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    builder
      .addCase(passwordResetChangeAction.pending, (state) => {
        return {
          ...state,
          passwordResetChange: {
            ...state.passwordResetChange,
            isChanging: true,
            message: undefined,
          },
        };
      })
      .addCase(passwordResetChangeAction.fulfilled, (state, action) => {
        return {
          ...state,
          passwordResetChange: {
            ...state.passwordResetChange,
            isChanging: false,
            message: action.payload,
            success: true,
          },
        };
      })
      .addCase(passwordResetChangeAction.rejected, (state, action) => {
        return {
          ...state,
          passwordResetChange: {
            ...state.passwordResetChange,
            isChanging: false,
            message: action.payload,
            success: false,
          },
        };
      });
  },
});

export default authSlice.reducer;
