import { useEffect } from "react";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IRootState, useAppDispatch } from "store";
import { passwordResetChangeAction } from "store/auth/authActions";
import { Pngs } from "res";
import styles from "./ResetPasswordScreen.module.css";

const RESET_PASSWORD_TITLE = "Set your new password";

const ResetPasswordScreen = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const { success, isChanging, message } = useSelector(
    (state: IRootState) => state.auth.passwordResetChange
  );
  const actionMessageStyle = `${styles.actionMessage} ${
    success ? styles.success : styles.error
  }`;

  useEffect(() => {
    if (success) {
      window.history.pushState({}, "", "/reset-password/success");
    }
  }, [success]);

  const onPasswordChange = (password: string) => {
    if (token) {
      dispatch(passwordResetChangeAction({ password, token }));
    }
  };

  return (
    <div className={styles.container}>
      <img src={Pngs.YoupetLogo} width={200} />
      <div className={styles.title}>{RESET_PASSWORD_TITLE}</div>
      <ResetPasswordForm
        isChanging={isChanging}
        isButtonDisabled={success}
        onPasswordChange={onPasswordChange}
      />
      {message && <div className={actionMessageStyle}>{message}</div>}
    </div>
  );
};

export default ResetPasswordScreen;
