import { Pngs } from "res";
import { constants } from "res";
import { firebaseLogEvent } from "utils/fbAnalytics";
import styles from "./DownloadAppComponent.module.css";

interface Props {
  isSmallScreen: boolean;
  id?: string;
  style?: string;
}
const DownloadAppComponent = ({ isSmallScreen, id, style }: Props) => {
  const finalContainerStyle = `${styles.container} ${style}`;
  const imageHeight = isSmallScreen ? 300 : 400;
  const onPlayStoreClick = () => {
    firebaseLogEvent("play_store_link");
    window.open(constants.appDownloadLinks.PLAY_STORE_LINK, "_blank");
  };

  const onAppStoreClick = () => {
    firebaseLogEvent("app_store_link");
    window.open(constants.appDownloadLinks.APP_STORE_LINK, "_blank");
  };

  return (
    <div className={finalContainerStyle} id={id}>
      <div className={styles.downloadImageContainer}>
        <img src={Pngs.Intro3} height={imageHeight} />
      </div>

      <div className={styles.downloadContentContainer}>
        <div className={styles.downloadContentTitle}>
          Get started with youpet today
        </div>
        <div className={styles.playStoreDownloadButton}>
          <img
            src={Pngs.PlayStore}
            width={141}
            height={48}
            onClick={onPlayStoreClick}
          />
          <img
            className={styles.appStoreDownloadButton}
            src={Pngs.AppStore}
            width={159.65}
            height={48}
            onClick={onAppStoreClick}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadAppComponent;
