import {
  ActionButton,
  BottomNavigationBar,
  DownloadAppComponent,
  TopNavigationBar,
} from "components";
import WelcomeHeader from "../components/WelcomeHeader";
import IntroMeta from "./components/IntroMeta";
import { useIsSmallScreen } from "hooks/layout/useIsSmallScreen";
import { Pngs, Svgs } from "res";
import styles from "./IntroScreen.module.css";
import YoutubeIntro from "./components/YoutubeIntro";
import { firebaseLogEvent } from "utils/fbAnalytics";

const DOWNLOAD_BUTTON_TEXT = "Download";

const IntroScreen = () => {
  const isSmallScreen = useIsSmallScreen();
  const imageHeight = isSmallScreen ? 300 : 400;

  const scrollToDownloads = () => {
    firebaseLogEvent("to_downloads_press");
    const downloadsElement = document.getElementById("download");
    if (downloadsElement) {
      downloadsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderSecondContent = () => {
    if (isSmallScreen) {
      return (
        <div className={styles.contentRow}>
          <div className={styles.imageContainer}>
            <img src={Pngs.Intro2} height={imageHeight} />
          </div>

          <div className={styles.text}>
            🌟 Share the joy of your pet's life on our dynamic platform. 📸🐾
            Upload images and videos, and engage with a pet-loving community
            through commenting and interactions. Spread the happiness by sharing
            these delightful moments with friends and family. Join us now and
            let's celebrate the love of pets together! 🎉🐕🐈
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.contentRow}>
          <div className={styles.text}>
            🌟 Share the joy of your pet's life on our dynamic platform. 📸🐾
            Upload images and videos, and engage with a pet-loving community
            through commenting and interactions. Spread the happiness by sharing
            these delightful moments with friends and family. Join us now and
            let's celebrate the love of pets together! 🎉🐕🐈
          </div>

          <div className={styles.imageContainer}>
            <img src={Pngs.Intro2} height={imageHeight} />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <IntroMeta />
      <TopNavigationBar
        style={styles.topNavigatioBar}
        RightActionButton={
          <ActionButton
            label={DOWNLOAD_BUTTON_TEXT}
            onClick={scrollToDownloads}
          />
        }
      />
      <div className={styles.content}>
        <div className={styles.innerContainer}>
          <WelcomeHeader />
          <div className={styles.text}>
            🐾 We're here to cater to pet owners and their beloved companions.
            <br />
            Share your pet's captivating photos and videos, and stay tuned for
            upcoming services that will meet all your pet-related needs.
            <br />
            Join our community and embark on an exciting journey to create a
            thriving platform dedicated to celebrating the joy pets bring to our
            lives.🌈
            <br />
            <br />
            🌟 Unleash the full potential of your pet's world with us! 🐕🐈
            <br />
            <br />
            🚀 In the near future, we have exciting plans:
            <br />
            <ol>
              <li>
                🐾 <b>Adoption</b>: Find your perfect furry friend through our
                adoption service. Browse a wide selection of pets available for
                adoption and connect with shelters and rescues.
              </li>
              <li>
                🚨 <b>Pet Missing Alert</b>: In the unfortunate event that your
                pet goes missing, our platform will provide a dedicated missing
                pet alert system.
              </li>
            </ol>
          </div>

          <div className={styles.contentRow}>
            <div className={styles.imageContainer}>
              <img src={Pngs.Intro1} height={imageHeight} />
            </div>

            <div className={styles.text}>
              🌟 Create personalized profiles for yourself and your beloved pets
              on our platform. Showcase your bond with a unique profile,
              including avatars and cover photos. Share adorable photos, videos,
              and captivating stories of your furry companions. Connect with
              fellow pet enthusiasts and let your pet's true colors shine. Join
              us now and let the pet-loving adventure begin! 🐾🌈
            </div>
          </div>

          {renderSecondContent()}

          <DownloadAppComponent
            id={"download"}
            style={styles.contentRow}
            isSmallScreen={isSmallScreen}
          />

          <div className={styles.teamTitle}>Quick look</div>

          <YoutubeIntro style={styles.youtube} />

          <div className={styles.teamTitle}>Founders</div>

          <div className={styles.memberInfoRowContainer}>
            <img src={Svgs.Nikos} height={120} width={120} />
            <div className={styles.memberInfoContainer}>
              <div className={styles.memberName}>Nikos Menekos</div>
              <div className={styles.memberDescription}>
                In loving memory of Nikos, one of our founders. Nikos was a
                devoted pet lover who cherished his beloved companion, Jessy. He
                played a pivotal role in shaping and building many integral
                parts of our pet platform. Nikos's passion for pets and
                dedication to our mission will always be remembered. Although he
                is no longer with us, his legacy lives on in our pet-loving
                community. We honor Nikos for his profound impact and the
                enduring bond he shared with Jessy.
              </div>
            </div>
          </div>

          <div className={styles.memberInfoRowContainer}>
            <img src={Svgs.Vasilis} height={120} width={120} />
            <div className={styles.memberInfoContainer}>
              <div className={styles.memberName}>Vasilis Menekos</div>
              <div className={styles.memberDescription}>
                An experienced and skilled developer who is the founder of our
                pet platform. With a background in working with startups,
                Vasilis brings his expertise to our pet platform. Passionate
                about pets, particularly dogs, he is dedicated to creating a
                seamless user experience. Drawing upon their knowledge of
                content creation and digital tools, Vasilis plays a vital role
                in shaping our platform and driving innovation.
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavigationBar />
    </div>
  );
};

export default IntroScreen;
