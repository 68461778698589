import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CheckBox.module.css";

interface Props {
  label?: string;
  labelComponent?: React.ReactNode;
  value: boolean;
  isDisabled?: boolean;
  onChange: (bool: boolean) => void;
  style?: string;
}

const CheckBox = ({
  label,
  labelComponent,
  value,
  isDisabled = false,
  onChange,
  style,
}: Props) => {
  const finalStyle = `${styles.container} ${style}`;
  const finalCheckBoxStyle = `${styles.checkbox} ${
    value && styles.checkboxChecked
  }`;

  const onCheckBoxValueChange = () => {
    if (!isDisabled) {
      onChange(!value);
    }
  };

  return (
    <div className={finalStyle}>
      <div className={finalCheckBoxStyle} onClick={onCheckBoxValueChange}>
        {value && <FontAwesomeIcon icon={"check"} />}
      </div>
      {label && <div className={styles.label}>{label}</div>}
      {labelComponent && (
        <div className={styles.labelComponentContainer}>{labelComponent}</div>
      )}
    </div>
  );
};

export default CheckBox;
