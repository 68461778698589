import Intro1 from "./pngs/intro/intro1.png";
import Intro2 from "./pngs/intro/intro2.png";
import Intro3 from "./pngs/intro/intro3.png";
import PlayStore from "./pngs/intro/playStore.png";
import AppStore from "./pngs/intro/appStore.png";

const YoupetLogo =
  "https://s3.us-west-1.amazonaws.com/youpet.store/assets/pngs/logo.png";

export const Pngs = { AppStore, Intro1, Intro2, Intro3, PlayStore, YoupetLogo };
