import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  ContactUsScreen,
  EmailUnsubscribeScreen,
  IntroScreen,
  OpenDownloadApp,
  PostRedirectScreen,
  ResetPasswordScreen,
  TOSAndPrivacyScreen,
} from "screens";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IntroScreen />} />
        <Route path="/tos-privacy" element={<TOSAndPrivacyScreen />} />
        <Route path="/contact-us" element={<ContactUsScreen />} />
        <Route
          path="/reset-password/:token"
          element={<ResetPasswordScreen />}
        />
        <Route path="/email-unsubscribe" element={<EmailUnsubscribeScreen />} />
        <Route path="/post/:postId" element={<PostRedirectScreen />} />
        <Route path="/open-download" element={<OpenDownloadApp />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
