import { createSlice } from "@reduxjs/toolkit";
import { signupInterestAction } from "./signupInterestActions";

export interface SignupInterestState {
  isLoading: boolean;
  resultMessage?: string;
}

const initialState = {
  isLoading: false,
  resultMessage: undefined,
} as SignupInterestState;

const signupInterestSlice = createSlice({
  name: "signupInterest",
  initialState,
  reducers: {},
  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    builder
      .addCase(signupInterestAction.pending, (state) => {
        return { ...state, resultMessage: undefined, isLoading: true };
      })
      .addCase(signupInterestAction.fulfilled, (state, action) => {
        return { ...state, resultMessage: action.payload, isLoading: false };
      })
      .addCase(signupInterestAction.rejected, (state, action) => {
        return {
          ...state,
          resultMessage: action.payload,
          isLoading: false,
        };
      });
  },
});

export default signupInterestSlice.reducer;
