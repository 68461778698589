import { Pngs } from "res";
import styles from "./TOSAndPrivacyScreen.module.css";

const TOSAndPrivacyScreen = () => {
  return (
    <div className={styles.container}>
      <img src={Pngs.YoupetLogo} width={200} />
      <div className={styles.title}>Terms of Service:</div>
      <div className={styles.body}>
        Welcome to our pet platform! By accessing and using our platform, you
        agree to comply with the following terms of service:
        <br />
        <ol>
          <li>
            Account Creation: To use our platform, you must create an account
            and provide accurate and complete information, including your name,
            email address, country of residence, and other information we may
            require. You may also create profiles for your pets and provide
            information such as their name, breed, and birthdate.
          </li>
          <li>
            Prohibited Conduct: You may not use our platform to engage in any
            illegal or prohibited conduct, including but not limited to posting
            pornographic or hate content, engaging in racist or discriminatory
            behavior, or engaging in any activity that violates the rights of
            others.
          </li>
          <li>
            User Content: You are solely responsible for any content you post on
            our platform, including text, images, and videos. By posting
            content, you grant us a non-exclusive, worldwide, royalty-free
            license to use, reproduce, and distribute your content for the
            purposes of operating and promoting our platform.
          </li>
          <li>
            User Interaction: Our platform allows you to interact with other
            users and their pets. You are solely responsible for any
            interactions you have with other users and their pets. We do not
            endorse, and are not responsible for, any content posted by users or
            any interactions between users.
          </li>
          <li>
            Termination: We reserve the right to terminate your account at any
            time if you violate these terms of service or engage in any conduct
            that we determine, in our sole discretion, to be inappropriate or
            harmful.
          </li>
        </ol>
      </div>

      <div className={styles.title}>Privacy Policy:</div>
      <div className={styles.body}>
        We are committed to protecting the privacy of our users and their pets.
        This privacy policy explains how we collect, use, and disclose personal
        information.
        <br />
        <ol>
          <li>
            Personal Information: We collect personal information when you
            create an account on our platform, such as your name, email address,
            and country of residence. You may also provide personal information
            when you create profiles for your pets. We may use this information
            to provide and improve our platform, to communicate with you, and to
            comply with our legal obligations.
          </li>
          <li>
            User Content: Our platform allows you to post content, including
            text, images, and videos. This content may include personal
            information about you or your pets. By posting content on our
            platform, you agree that this content will be visible to other users
            of our platform.
          </li>
          <li>
            Data Subject Rights: Under the GDPR, you have certain rights with
            respect to your personal data, including the right to access,
            correct, and delete your personal data. You also have the right to
            object to the processing of your personal data and the right to data
            portability. To exercise these rights, please contact us using the
            contact information provided below.
          </li>
          <li>
            Data Security: We take appropriate technical and organizational
            measures to protect personal data from unauthorized access,
            disclosure, or alteration. However, no system can be completely
            secure, and we do not guarantee that your personal data will be
            completely secure from unauthorized access or use.
          </li>
          <li>
            International Data Transfers: We may transfer personal data to
            countries outside of the European Economic Area (EEA) that may not
            provide the same level of protection for personal data as the EEA.
            However, we will take appropriate safeguards to ensure that such
            transfers comply with the GDPR.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TOSAndPrivacyScreen;
