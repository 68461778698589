import { Helmet } from "react-helmet";

const IntroMeta = () => {
  return (
    <Helmet>
      <title>Youpet - First pet owners' social network platform!</title>
      <meta
        name="description"
        content="Join Youpet - Connect with other pet owners and share your pet moments!"
      />
      <meta
        name="keywords"
        content="pets, social network, community, pet platform, animals, pet owners, dogs, cats, adoption, missing pet, mobile app, download, ios, android, profile, share, like"
      />
      <meta name="author" content="Youpet" />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default IntroMeta;
