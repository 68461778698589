import CircularProgress from "react-loading-indicators";

interface Props {
  size?: number;
}

const LoadingSpinner = ({ size = 20 }: Props) => {
  const finalSize = `${size}px`;

  return (
    <CircularProgress
      style={{
        height: finalSize,
        width: finalSize,
        color: "white",
        fontSize: "10px",
      }}
    />
  );
};

export default LoadingSpinner;
