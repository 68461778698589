import { useEffect, useState } from "react";

export const useFontsLoaded = () => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    const checkFontsLoad = async () => {
      try {
        await document.fonts.ready;
      } catch (error) {
        console.error("Error loading fonts:", error);
      } finally {
        setIsFontLoaded(true);
      }
    };

    checkFontsLoad();
  }, []);

  return isFontLoaded;
};
