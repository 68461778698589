import styles from "./YoutubeIntro.module.css";

const YOUTUBE_INTRO_LINK = "https://www.youtube.com/embed/1y0fSlmaOaU";

interface Props {
  style?: string;
}

const YoutubeIntro = ({ style }: Props) => {
  const finalStyle = `${styles.container} ${style}`;

  return (
    <div className={finalStyle}>
      <iframe
        width={300}
        height={300 * (16 / 9)}
        src={YOUTUBE_INTRO_LINK}
        title="Youpet Intro"
        allowFullScreen
        frameBorder={0}
      ></iframe>
    </div>
  );
};

export default YoutubeIntro;
