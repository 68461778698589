import { Pngs } from "res";
import styles from "./ContactUsScreen.module.css";

const SUPPORT_EMAIL = "support@youpet.org";

const ContactUsScreen = () => {
  const onEmailPress = () => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  return (
    <div className={styles.container}>
      <img src={Pngs.YoupetLogo} width={200} />
      <div className={styles.contactInfoContainer}>
        <div className={styles.contactInfoTitle}>Contact us</div>
        <div className={styles.contactInfoCategory}>
          Email:
          <div onClick={onEmailPress} className={styles.contactInfoContent}>
            {SUPPORT_EMAIL}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
