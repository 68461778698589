import { Pngs } from "res";
import { useIsSmallScreen } from "hooks/layout/useIsSmallScreen";
import styles from "./TopNavigationBar.module.css";

interface Props {
  RightActionButton?: React.ReactNode;
  style?: string;
}

const TopNavigationBar = ({ RightActionButton, style }: Props) => {
  const finalStyle = `${styles.container} ${style}`;
  const isSmallScreen = useIsSmallScreen();
  const finalImageWidth = isSmallScreen ? 140 : 160;

  return (
    <div className={finalStyle}>
      <img src={Pngs.YoupetLogo} width={finalImageWidth} />
      {RightActionButton && RightActionButton}
    </div>
  );
};

export default TopNavigationBar;
