import { createAsyncThunk } from "@reduxjs/toolkit";
import { passwordResetChange } from "api";

export const passwordResetChangeAction = createAsyncThunk<
  string,
  { password: string; token: string },
  { rejectValue: string }
>("auth/passwordResetChange", async (data, { rejectWithValue }) => {
  try {
    await passwordResetChange(data.password, data.token);
    return "Password changed successfully";
  } catch (err: any) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});
