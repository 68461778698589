import { ActionButton, Input } from "components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import styles from "./LoginForm.module.css";

YupPassword(yup);

const simpleTypePassword = yup.string().required("Please enter password");
const complexTypePassword = yup
  .string()
  .required("Please enter password")
  .min(8, "Password is too short - should be 8 chars minimum.")
  .max(16, "Password is too long - should be 16 chars maximum.")
  .minNumbers(1, "Password should contain 1 digit")
  .minUppercase(1, "password must contain at least 1 upper case letter");

const EMAIL_PLACEHOLDER = "Type Email";
const PASSWORD_PLACEHOLDER = "Type password";

interface FormData {
  email: string;
  password: string;
}

interface Props {
  variant?: "default" | "simple";
  isSubmitting: boolean;
  isButtonDisabled: boolean;
  submitButtonLabel: string;
  onSubmitPress: (email: string, password: string) => void;
}

const LoginForm = ({
  variant = "default",
  isSubmitting,
  isButtonDisabled,
  submitButtonLabel,
  onSubmitPress,
}: Props) => {
  const passwordYup =
    variant === "default" ? complexTypePassword : simpleTypePassword;
  const schema = yup
    .object({
      email: yup
        .string()
        .email("Please enter email")
        .required("Please enter email"),
      password: passwordYup,
    })
    .required();

  const formConfig = {
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>(formConfig);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    onSubmitPress(email, password);
  });

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              type="email"
              placeholder={EMAIL_PLACEHOLDER}
              error={errors.email?.message}
              value={value}
              onChange={onChange}
            />
          )}
          name="email"
        />
      </div>

      <div className={styles.inputContainer}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              type="password"
              placeholder={PASSWORD_PLACEHOLDER}
              error={errors.password?.message}
              value={value}
              onChange={onChange}
            />
          )}
          name="password"
        />
      </div>

      <ActionButton
        style={styles.bottomButton}
        label={submitButtonLabel}
        isLoading={isSubmitting}
        isDisabled={isButtonDisabled}
        onClick={onSubmit}
      />
    </div>
  );
};

export default LoginForm;
