import { createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "api/authAPI";
import { updateNotificationsSettingsMe } from "api/notificationsSettingsAPI";
import { NotificationsSettingsData } from "types/interfaces/NotificationsSettings";

export const emailUnsubscribeAction = createAsyncThunk<
  undefined,
  { email: string; password: string },
  { rejectValue: string }
>(
  "emailUnsubscribe/emailUnsubscribeAction",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    const newNotificationsSettingsData: NotificationsSettingsData = {
      email: {
        enabled: false,
      },
    };

    try {
      const { token } = await login(email, password);
      await updateNotificationsSettingsMe(newNotificationsSettingsData, token);
    } catch (err: any) {
      const error = err as Error;
      return rejectWithValue(error.message);
    }
  }
);
