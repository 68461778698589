import EmailUnsubscribeScreenComponent from "./EmailUnsubscribeScreen.component";
import { IRootState, useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { emailUnsubscribeAction } from "store/emailUnsubscribe/emailUnsubscribeActions";

const SUPPORT_EMAIL = "support@youpet.org";

const EmailUnsubscribeScreen = () => {
  const dispatch = useAppDispatch();
  const { isSubmitting, success, error } = useSelector(
    (state: IRootState) => state.emailUnsubscribe
  );
  const isSubmitButtonDisabled = success || Boolean(error);

  const onSubmitPress = (email: string, password: string) => {
    dispatch(emailUnsubscribeAction({ email, password }));
  };

  const contactUsPrompt = () => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  return (
    <EmailUnsubscribeScreenComponent
      isSubmitting={isSubmitting}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      onSubmitPress={onSubmitPress}
      error={error}
      success={success}
      contactUsPrompt={contactUsPrompt}
      contactEmail={SUPPORT_EMAIL}
    />
  );
};

export default EmailUnsubscribeScreen;
