import ActionButton from "components/ActionButton";
import DownloadAppComponent from "components/DownloadAppComponent";
import TopNavigationBar from "components/TopNavigationBar";
import { useIsSmallScreen } from "hooks/layout/useIsSmallScreen";
import styles from "./RedirectDownloadComponent.module.css";

const REDIRECT_OPEN_IN_APP_TEXT = "Open in app";

interface Props {
  handleDeepLinkClick: () => void;
  message?: string;
}

const RedirectDownloadComponent = ({ handleDeepLinkClick, message }: Props) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <div className={styles.container}>
      <TopNavigationBar
        RightActionButton={
          <ActionButton
            label={REDIRECT_OPEN_IN_APP_TEXT}
            onClick={handleDeepLinkClick}
          />
        }
      />
      <div className={styles.content}>
        {message && <div className={styles.message}>{message}</div>}
        <DownloadAppComponent
          style={styles.downloadContent}
          isSmallScreen={isSmallScreen}
        />
      </div>
    </div>
  );
};

export default RedirectDownloadComponent;
