import { RedirectDownloadComponent } from "components";

const OpenDownloadApp = () => {
  const handleDeepLinkClick = () => {
    window.location.href = `youpet://`;
  };

  return (
    <RedirectDownloadComponent handleDeepLinkClick={handleDeepLinkClick} />
  );
};

export default OpenDownloadApp;
