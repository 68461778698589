import styles from "./WelcomeHeader.module.css";

const WELCOME_TITLE = "Welcome to Youpet!";

const WelcomeHeader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.welcomeMessage}>{WELCOME_TITLE}</div>
    </div>
  );
};

export default WelcomeHeader;
