import { API } from "./API";

const login = async (email: string, password: string) => {
  return await API.post(
    "/api/v1/auth/login",
    {
      data: { email, password },
    },
    false
  );
};

const passwordResetChange = async (password: string, token: string) => {
  return await API.post("/api/v1/auth/password-reset-change", {
    data: { password, token },
  });
};

export { login, passwordResetChange };
