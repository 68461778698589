import { ActionButton, Input } from "components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import YupPassword from "yup-password";
import styles from "./ResetPasswordForm.module.css";

YupPassword(yup);

const PASSWORD_PLACEHOLDER = "New Password";
const PASSWORD_REPEAT_PLACEHOLDER = "Repeat Password";

interface FormData {
  password: string;
  repeatPassword: string;
}

const schema = yup
  .object({
    password: yup
      .string()
      .required("Please enter password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .max(16, "Password is too long - should be 16 chars maximum.")
      .minNumbers(1, "Password should contain 1 digit")
      .minUppercase(1, "password must contain at least 1 upper case letter"),
    repeatPassword: yup
      .string()
      .required("Please enter repeat password")
      .oneOf([yup.ref("password")], "Passwords does not match"),
  })
  .required();

const formConfig = {
  defaultValues: { password: "", repeatPassword: "" },
  resolver: yupResolver(schema),
};

interface Props {
  isChanging: boolean;
  isButtonDisabled: boolean;
  onPasswordChange: (password: string) => void;
}

const ResetPasswordForm = ({
  isChanging,
  isButtonDisabled,
  onPasswordChange,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>(formConfig);

  const onSubmit = handleSubmit(async ({ password }) => {
    onPasswordChange(password);
  });

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              type="password"
              placeholder={PASSWORD_PLACEHOLDER}
              error={errors.password?.message}
              value={value}
              onChange={onChange}
            />
          )}
          name="password"
        />
      </div>

      <div className={styles.inputContainer}>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Input
              type="password"
              placeholder={PASSWORD_REPEAT_PLACEHOLDER}
              error={errors.repeatPassword?.message}
              value={value}
              onChange={onChange}
            />
          )}
          name="repeatPassword"
        />
      </div>

      <ActionButton
        style={styles.bottomButton}
        label="Save Password"
        isLoading={isChanging}
        isDisabled={isButtonDisabled}
        onClick={onSubmit}
      />
    </div>
  );
};

export default ResetPasswordForm;
