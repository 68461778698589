import { createSlice } from "@reduxjs/toolkit";
import { emailUnsubscribeAction } from "./emailUnsubscribeActions";

export interface EmailUnsubscribeState {
  isSubmitting: boolean;
  success: boolean;
  error?: string;
}

const initialState: EmailUnsubscribeState = {
  isSubmitting: false,
  success: false,
  error: undefined,
};

const emailUnsubscribeSlice = createSlice({
  name: "emailUnsubscribe",
  initialState,
  reducers: {},
  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    builder
      .addCase(emailUnsubscribeAction.pending, (state) => {
        return {
          ...state,
          isSubmitting: true,
          error: undefined,
        };
      })
      .addCase(emailUnsubscribeAction.fulfilled, (state) => {
        return {
          ...state,
          isSubmitting: false,
          success: true,
        };
      })
      .addCase(emailUnsubscribeAction.rejected, (state) => {
        return {
          ...state,
          isSubmitting: false,
          error:
            "Failed to unsubscribe from email notifications. Please contact support.",
        };
      });
  },
});

export default emailUnsubscribeSlice.reducer;
