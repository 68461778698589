import { Pngs } from "res";
import styles from "./BottomNavigationBar.module.css";
import SocialButtons from "./components/SocialButtons";
import { useNavigate } from "react-router-dom";

const BottomNavigationBar = () => {
  const navigate = useNavigate();

  const onContactUsPress = () => {
    navigate("/contact-us");
  };

  const onPrivacyTOSPress = () => {
    navigate("/tos-privacy");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={Pngs.YoupetLogo} width={200} />
        <div className={styles.column}>
          <div className={styles.socialTitle}>Social</div>
          <SocialButtons />
        </div>
        <div className={styles.column}>
          <div className={styles.link} onClick={onContactUsPress}>
            Contact us
          </div>
          <div
            className={`${styles.link} ${styles.linkMargin}`}
            onClick={onPrivacyTOSPress}
          >
            Privacy and ToS
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNavigationBar;
