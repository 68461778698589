import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { signupInterest } from "api";

export const signupInterestAction = createAsyncThunk<
  string,
  { email: string },
  { rejectValue: string }
>("signupInterest/signup", async (data, { rejectWithValue }) => {
  try {
    await signupInterest(data.email);
    return "Signed up successfully!";
  } catch (err: any) {
    const error = err as Error;
    return rejectWithValue(error.message);
  }
});
