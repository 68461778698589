import { RedirectDownloadComponent } from "components";
import { useParams } from "react-router-dom";

const POST_REDIRECT_MESSAGE =
  "Download our app for Android or iOS to open this post.";

const PostRedirectScreen = () => {
  const { postId } = useParams();

  const handleDeepLinkClick = () => {
    window.location.href = `youpet://post/${postId}`;
  };

  return (
    <RedirectDownloadComponent
      message={POST_REDIRECT_MESSAGE}
      handleDeepLinkClick={handleDeepLinkClick}
    />
  );
};

export default PostRedirectScreen;
