import { LoadingSpinner } from "components";
import styles from "./ActionButton.module.css";

interface Props {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  style?: string;
}

const ActionButton = ({
  label,
  onClick,
  isLoading,
  isDisabled,
  style,
}: Props) => {
  const disabledClass = isDisabled ? styles.containerDisabled : "";

  const onButtonClick = () => {
    if (!isLoading && !isDisabled) {
      onClick();
    }
  };

  return (
    <div
      className={`${styles.container} ${style} ${disabledClass}`}
      onClick={onButtonClick}
    >
      {!isLoading ? (
        <div className={styles.label}>{label}</div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default ActionButton;
