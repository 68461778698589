import { LoginForm } from "components";
import { Pngs } from "res";
import styles from "./EmailUnsubscribeScreen.module.css";

const EMAIL_UNSUBSCRIBE_TITLE = "Unsubscribe from emails";
const EMAIL_UNSUBSCRIBE_SUCCESS_MESSAGE =
  "Unsubscribed from email notifications successfully!";
const EMAIL_UNSUBSCRIBE_BUTTON_TEXT = "Unsubscribe";

interface Props {
  success: boolean;
  isSubmitting: boolean;
  onSubmitPress: (email: string, password: string) => void;
  contactUsPrompt: () => void;
  isSubmitButtonDisabled?: boolean;
  error?: string;
  contactEmail: string;
}

const EmailUnsubscribeScreenComponent = ({
  success,
  isSubmitting,
  contactUsPrompt,
  isSubmitButtonDisabled = false,
  onSubmitPress,
  error,
  contactEmail,
}: Props) => {
  const actionMessageSuccessStyle = `${styles.actionMessage} ${styles.success}`;
  const actionMessageErrorStyle = `${styles.actionMessage} ${styles.error}`;

  return (
    <div className={styles.container}>
      <img src={Pngs.YoupetLogo} width={200} />
      <div className={styles.title}>{EMAIL_UNSUBSCRIBE_TITLE}</div>
      <LoginForm
        variant="simple"
        isSubmitting={isSubmitting}
        isButtonDisabled={isSubmitButtonDisabled}
        submitButtonLabel={EMAIL_UNSUBSCRIBE_BUTTON_TEXT}
        onSubmitPress={onSubmitPress}
      />
      {success && (
        <div className={actionMessageSuccessStyle}>
          {EMAIL_UNSUBSCRIBE_SUCCESS_MESSAGE}
        </div>
      )}
      {error && (
        <div className={styles.errorContainer}>
          <div className={actionMessageErrorStyle}>{error}</div>
          <div onClick={contactUsPrompt} className={styles.contactInfoContent}>
            {contactEmail}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailUnsubscribeScreenComponent;
