// Team
import Giorgos from "./svgs/team/giorgos.svg";
import Nikos from "./svgs/team/nikos.svg";
import Vasilis from "./svgs/team/vasilis.svg";

// Social
import FacebookIcon from "./svgs/social/facebook.svg";
import InstagramIcon from "./svgs/social/instagram.svg";
import TikTokIcon from "./svgs/social/tiktok.svg";
import TwitterIcon from "./svgs/social/twitter.svg";
import YoutubeIcon from "./svgs/social/youtube.svg";

const teamIcons = { Giorgos, Nikos, Vasilis };
const socialIcons = {
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon,
};

export const Svgs = { ...teamIcons, ...socialIcons };
