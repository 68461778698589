import { NotificationsSettingsData } from "types/interfaces/NotificationsSettings";
import { API } from "./API";

const updateNotificationsSettingsMe = (
  settings: NotificationsSettingsData,
  customAuthToken: string
) => {
  return API.put(
    "/api/v1/notifications-settings/me/update",
    {
      data: { settings },
    },
    true,
    customAuthToken
  );
};

export { updateNotificationsSettingsMe };
