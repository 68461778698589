// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCrWkTeee-plERO_8rKYQR9728T18Ow7r4",
  authDomain: "youpet-web.firebaseapp.com",
  projectId: "youpet-web",
  storageBucket: "youpet-web.appspot.com",
  messagingSenderId: "661768885078",
  appId: "1:661768885078:web:c5af05e41cda935ee3bcf7",
  measurementId: "G-7P8WVR38Z3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firebaseLogEvent = (event: string) => {
  logEvent(analytics, event);
};
