const INSTAGRAM_LINK = "https://www.instagram.com/youpetorg/";
const FACEBOOK_LINK = "https://www.facebook.com/youpetorg";
const TIKTOK_LINK = "https://www.tiktok.com/@youpet.community";
const TWITTER_LINK = "https://twitter.com/youpetorg";
const YOUTUBE_LINK = "https://www.youtube.com/@youpetorg";

export const socialLinks = {
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  TIKTOK_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
};
