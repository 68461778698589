import Axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:4000";

export interface ICustomError {
  status: number;
  message: string;
}

class CustomError extends Error {
  constructor(public status: number, message: string) {
    super(message);
  }
}

export class API {
  static async call(
    method: Method,
    input: string,
    init: AxiosRequestConfig,
    auth: boolean = true,
    isMedia: boolean = false,
    customAuthToken?: string
  ) {
    try {
      const contentType = !isMedia ? "application/json" : "multipart/form-data";
      const configHeaders = Object.assign({
        "content-type": contentType,
        Authorization: customAuthToken,
      });
      const config: AxiosRequestConfig = Object.assign({}, { method }, init);

      const response = await Axios(input, {
        baseURL,
        headers: configHeaders,
        ...config,
      });

      const json = response.data;
      return json;
    } catch (err: any) {
      if (err.response) {
        const response: AxiosResponse = err.response;
        const statusCode = response.status;
        const errorMessage = response.data.error;
        throw new CustomError(statusCode, errorMessage);
      } else if (err.request) {
        // add a toast message here
        throw new Error(err.message);
      } else {
        throw new Error(err);
      }
    }
  }

  static get(input: string, init: AxiosRequestConfig, auth: boolean = true) {
    return API.call("GET", input, init, auth);
  }

  static put(
    input: string,
    init: AxiosRequestConfig,
    auth: boolean = true,
    customAuthToken?: string
  ) {
    return API.call("PUT", input, init, auth, false, customAuthToken);
  }

  static async post(
    input: string,
    init: AxiosRequestConfig,
    auth: boolean = true,
    isMedia: boolean = false
  ) {
    return API.call("POST", input, init, auth, isMedia);
  }

  static delete(input: string, init: AxiosRequestConfig, auth: boolean = true) {
    return API.call("DELETE", input, init, auth);
  }
}
